import { getPassiveEventOption } from '@mop/shared/utils/util';

let targetElement: HTMLElement | undefined;
let documentListenerAdded = false;
let initialClientY = -1;
let previousTarget: HTMLElement | undefined;

/**
 * Prevent default touch behaviour
 *
 * @param {TouchEvent} event Touch event
 * @returns {undefined}
 */
function preventDefault(event: TouchEvent) {
  event = event || window.event;

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom)
  if (event.touches.length > 1) {
    return;
  }

  if (event.preventDefault) {
    event.preventDefault();
  }
}

/**
 * Validates bouncing
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions}
 *
 */
function isTargetElementTotallyScrolled(targetElement: HTMLElement) {
  return targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;
}

function handleScroll(event: TouchEvent, targetElement: HTMLElement) {
  if (targetElement.scrollHeight === targetElement.offsetHeight) {
    event.stopPropagation();
    return;
  }
  const scrollUp = event.targetTouches[0].clientY - initialClientY > 0;

  // element is at the top of its scroll
  if (targetElement && targetElement.scrollTop === 0 && scrollUp) {
    preventDefault(event);
    return;
  }

  // element is at the bottom of its scroll
  if (isTargetElementTotallyScrolled(targetElement) && !scrollUp) {
    preventDefault(event);
    return;
  }
  event.stopPropagation();
}

export function lockScroll(target: HTMLElement, preserveHistory = true) {
  if (!target) {
    return;
  }
  // enable history restore for layers which go over each other (hotspot layer)
  if (preserveHistory && targetElement !== target) {
    previousTarget = targetElement;
  }
  targetElement = target;

  const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
  document.getElementById('wrapper')?.style.setProperty('--sticky-scroll-bar-width', `${scrollBarWidth}px`);

  document.body.classList.add(constants.BODY_CLASS_NO_SCROLL);
  targetElement.ontouchstart = function ontouchstart(event) {
    if (event.targetTouches.length === 1) {
      // detect single touch
      initialClientY = event.targetTouches[0].clientY;
    }
  };
  targetElement.ontouchmove = function ontouchmove(event) {
    if (event.targetTouches.length === 1) {
      // detect single touch
      handleScroll(event, target);
    }
  };

  if (!documentListenerAdded) {
    document.addEventListener('touchmove', preventDefault, getPassiveEventOption(false));
    documentListenerAdded = true;
  }
}

export function releaseScroll() {
  if (targetElement) {
    targetElement.ontouchstart = null;
    targetElement.ontouchmove = null;
  }
  document.body.classList.remove(constants.BODY_CLASS_NO_SCROLL);

  if (documentListenerAdded) {
    document.removeEventListener('touchmove', preventDefault);
    documentListenerAdded = false;
  }
  // Reset initial clientY
  initialClientY = -1;
  targetElement = undefined;
  // restore previously locked target
  if (previousTarget !== undefined) {
    // lock(previousTarget);
    lockScroll(previousTarget);
    previousTarget = undefined;
  }
}
