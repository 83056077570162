<script setup lang="ts">
import type { Timer } from '@mop/types';
import type { OverlayGroup } from '@/types/overlay';

defineOptions({
  name: 'MopOverlayManager',
});

const props = defineProps({
  group: {
    type: String as PropType<OverlayGroup>,
    required: true,
  },
  showShadow: {
    type: Boolean,
    default: false,
  },
});

const overlay = useMopOverlay(props.group);

const overlayState = computed(() => {
  const activeOverlay = overlay.activeOverlayRef.value;
  const componentName = activeOverlay?.componentName;
  const activeOverlayType = activeOverlay?.type;
  const overlayGroupList = overlay.overlayGroupListRef?.value || [];
  const showShadow = overlay.showShadowRef.value;
  const shadowColor = activeOverlay?.override?.shadowColor;
  const mouseLeaveTimeout = activeOverlay?.override?.mouseLeaveTimeout;
  const closeOnMouseLeave = activeOverlay?.override?.closeOnMouseLeave;

  return {
    overlay,
    activeOverlay,
    componentName,
    activeOverlayType,
    overlayGroupList,
    showShadow,
    shadowColor,
    closeOnMouseLeave,
    mouseLeaveTimeout,
  };
});

let listenForShadowEvents = false;

onMounted(() => {
  let closeShadowTimer: Timer;
  watch(overlay.showShadowRef, (showShadow) => {
    clearTimeout(closeShadowTimer);
    if (!showShadow) {
      listenForShadowEvents = false;
      return;
    }
    closeShadowTimer = setTimeout(() => {
      listenForShadowEvents = true;
    }, 300);
  });
});

let closeTimer: Timer;
const closeAllOverlays = async () => {
  if (overlayState.value.overlay.canClose()) {
    await overlayState.value.overlay.closeAll();
  }
};

function cancelCloseTimer() {
  if (!listenForShadowEvents) {
    return;
  }
  clearTimeout(closeTimer);
}

function startCloseTimer() {
  if (!listenForShadowEvents || !overlayState.value.closeOnMouseLeave) {
    return;
  }
  closeTimer = setTimeout(async () => {
    await closeAllOverlays();
  }, overlayState.value.mouseLeaveTimeout);
}
</script>

<template>
  <div>
    <div
      v-if="showShadow"
      :class="[
        'overlay-shadow',
        overlayState.shadowColor ? `overlay-shadow--${overlayState.shadowColor.toLowerCase()}` : '',
        overlayState.showShadow ? 'overlay-shadow--visible' : '',
        overlayState.componentName ? `overlay-shadow--${overlayState.componentName.toLowerCase()}` : '',
        overlayState.activeOverlayType ? `overlay-shadow--${overlayState.activeOverlayType.toLowerCase()}` : '',
      ]"
      @click="closeAllOverlays"
      @mouseenter="startCloseTimer"
      @mouseleave="cancelCloseTimer"
    />
    <template v-if="overlayState.overlayGroupList.length">
      <MopOverlay
        v-for="groupOverlay in overlayState.overlayGroupList"
        :key="groupOverlay.componentKey"
        :overlay="groupOverlay"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
$backgroundshadow: rgba(0, 0, 0, 0.5);

.overlay-shadow {
  @include z(layers, shadow, true);

  display: block;
  opacity: 0;
  transition:
    opacity 250ms linear,
    z-index 250ms step-end;

  @include apply-print {
    display: none;
  }
}

.overlay-shadow--visible {
  @include z(layers, shadow);

  background-color: $brand;
  position: fixed;
  opacity: 0.5;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  transition: opacity 250ms linear;

  &.overlay-shadow--mopcategoryfilteroverlay {
    @include z(layers, above-shadow);

    top: 0;
    top: var(--navh);
  }
}

.overlay-shadow--transparent {
  opacity: 0;
}

.overlay-shadow--bottom {
  @include z(layers, layer);
}

.overlay-shadow--centered,
.overlay-shadow--mopcountryselector {
  @include z(layers, fullscreen-layer);
}
</style>
